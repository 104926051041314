<template lang="pug">
<router-view/>
title Скарлетт и Монарх
</template>

<script>
import "./assets/scss/index.scss"

export default {
  
}
</script>